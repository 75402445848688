import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    profileModalOpen: false,
    activeLink: null
}

const profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        toggleProfileModal: (state, action) => {
            state.profileModalOpen = action.payload;
        },
        setActiveLink: (state, action) => {
            state.activeLink = action.payload;
        },
    }
})

export const profileActions = profileSlice.actions;

export default profileSlice;
