import React, {useEffect, useRef, useState} from "react";
import styles from './TherapyBoard.module.css';
import {Alert, Col, Form, FormGroup, Row} from "react-bootstrap";
import axios from "../../axios/axios";
import SearchBar from "./SearchWrapper/SearchBar/SearchBar";
import {useForm} from "react-hook-form";
import Button from "../UI/Buttons/Button";
import DiagnosisList from "./DiagnosisList/DiagnosisList";
import ArticlesList from "./ArticlesList/ArticlesList";
import {useDispatch, useSelector} from "react-redux";
import {therapyActions} from "../../store/therapy-slice";
import {sendTherapy} from "../../store/therapy-actions";
import ComponentToPrint from "../ComponentToPrint/ComponentToPrint";
import {useReactToPrint} from "react-to-print";
import TherapySentModal from "../Modals/TherapySentModal/TherapySentModal";
import TherapySendConfirmationModal from "../Modals/TherapySendConfirmationModal/TherapySendConfirmationModal";

const TherapyBoard = () => {

    const [articleArray, setArticleArray] = useState();
    const [diagnosisArray, setDiagnosisArray] = useState();
    const [articleListAlert, setArticleListAlert] = useState(false);
    const [showTherapySendConfirmationModal, setShowTherapySendConfirmationModal] = useState(false)

    const dispatch = useDispatch();
    const therapyDiagnosis = useSelector((state) => state.therapy.diagnosis);
    const therapyArticles = useSelector((state) => state.therapy.articlesForTherapyItemRequests);
    const showTherapySentModal = useSelector((state) => state.therapy.showTherapySentModal)

    const printRef = useRef();

    const {register, handleSubmit, formState: {errors}, getValues} = useForm({
        defaultValues: {
            email: ""
        }
    });

    const clearTherapy = () => {
        document.getElementById('form').reset();
        dispatch(therapyActions.removeAllArticlesAndDiagnosisFromTherapy());
    }

    const printAction = useReactToPrint({
        content: () => printRef.current
    });

    useEffect(() => {
        axios.get('/item/names')
            .then(response => {
                setArticleArray(response.data)
                // dispatch(allArticles(response.data))
            })
            .catch(error => {
                console.log("Error se desio - names" + error);
                // dispatch(fetchArticlesFailed());
            })
    }, [])

    useEffect(() => {
        axios.get('/therapy_diagnosis')
            .then(response => {
                setDiagnosisArray(response.data)
            })
            .catch(error => {
                console.log("Error se desio - diagnosis" + error);
                // dispatch(fetchArticlesFailed());
            })
    }, [])


    const openTherapySentConfirmationModal =()=> {
        if (therapyArticles.length === 0) {
            setArticleListAlert(true);
        } else {
            setShowTherapySendConfirmationModal(true);
        }
    }

    const closeTherapySentConfirmationModal =()=>{
        setShowTherapySendConfirmationModal(false)
    }

    const submitTherapy = () => {
            let therapyObj;
            const values = getValues();

            if(values.textArea1) {
                localStorage.setItem('textArea1', JSON.stringify(values.textArea1))
            }
            if(values.textArea2) {
                localStorage.setItem('textArea2', JSON.stringify(values.textArea2))
            }
            if(values.textArea3) {
                localStorage.setItem('textArea3', JSON.stringify(values.textArea3))
            }

            therapyObj = {
                userEmail: values.email,
                userAuthorizedId: parseInt(localStorage.getItem("id")),
                therapyDiagnosisId: therapyDiagnosis.length > 0 ? therapyDiagnosis[0].id : null,
                name: "Terapija " + localStorage.getItem('firstName') + " " + localStorage.getItem('secondName'),
                therapyItemRequests: therapyArticles
            }
            dispatch(sendTherapy(therapyObj));
            setShowTherapySendConfirmationModal(false)
    }

    const onKeyDown =(key)=> {
        if ((key.charCode || key.keyCode) === 13) {
            key.preventDefault();
        }
    }

    return (
        <div className={styles.TherapyBoardWrapper}>
            <Form onSubmit={handleSubmit(openTherapySentConfirmationModal)} onKeyDown={onKeyDown}
                  id="form">
                <div className={styles.FormContentWrapper}>
                    <Row className="m-4">
                        <Col lg={5}>
                            <div className={styles.TherapyLeftPane}>
                                <h3><strong>Nova terapija za:</strong></h3>
                                <Form.Label htmlFor="name">Ime i prezime pacijenta</Form.Label>
                                <Form.Control id="name" placeholder="Unesite ime i prezime pacijenta"
                                              {...register("name", {
                                                  required: "Ovo polje ne može biti prazno",
                                                  // pattern: {
                                                  //     value: /^(\d{3})\s([A-Za-z\sé])([\d\s-])$/,
                                                  //     message: "Uneseno ime nije validnog formata"
                                                  // },
                                                  maxLength: 50
                                              })}
                                              autoComplete="off"
                                />
                                {errors.name && errors.name.type === "maxLength" && <Alert style={{marginTop:"0.5rem"}} variant={"danger"}>Možete uneti najviše 50 karaktera.</Alert>}
                                {errors.name && errors.name.type !== "maxLength" && <Alert style={{marginTop:"0.5rem"}} variant={"danger"}>{errors.name.message}</Alert>}
                                <br/>
                                <Form.Label htmlFor="email">E-mail pacijenta</Form.Label>
                                <Form.Control id="email" placeholder="Unesite imejl adresu pacijenta"
                                              {...register("email", {
                                                  required: "Ovo polje ne može biti prazno",
                                                  pattern: {
                                                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                      message: "Unesena adresa nije validnog formata"
                                                  }
                                              })}
                                              autoComplete="off"
                                />
                                {errors.email && <Alert style={{marginTop:"0.5rem"}} variant={"danger"}>{errors.email.message}</Alert>}
                                <br/>
                                <FormGroup style={{width:"100%"}}>
                                    <Form.Label className="d-flex justify-content-start">Dijagnoza</Form.Label>
                                    <SearchBar data={diagnosisArray} diagnosis />
                                </FormGroup>
                                <br/>
                                <DiagnosisList/>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className={styles.TherapyRightPane}>
                                <h3><strong>Terapija:</strong></h3>
                                <Form.Label htmlFor="email">Lekovi</Form.Label>
                                <div className={styles.ArticlesSearchInput}>
                                    <SearchBar data={articleArray}/>
                                    <br/>
                                    {articleListAlert &&
                                        <Alert dismissible onClose={()=>setArticleListAlert(false)} variant="danger">
                                            Niste dodali nijedan artikal u terapiju
                                        </Alert>
                                    }
                                    <ArticlesList/>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <Row className="m-5">
                        <Form.Group className="mb-3" controlId="textArea1">
                            <Form.Label>Polje 1</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                          {...register("textArea1", {
                                              required: "Ovo polje ne može biti prazno",
                                              maxLength: 500
                                          })}
                            />
                            {errors.textArea1 && <Alert style={{marginTop:"0.5rem"}} variant={"danger"}>{errors.textArea1.message}</Alert>}
                        </Form.Group>
                        <br/>
                        <Form.Group className="mb-3" controlId="textArea2">
                            <Form.Label>Polje 2</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                          {...register("textArea2", {
                                              required: false,
                                              maxLength: 500
                                          })}
                            />
                            {errors.textArea2 && <Alert style={{marginTop:"0.5rem"}} variant={"danger"}>{errors.textArea2.message}</Alert>}
                        </Form.Group>
                        <br/>
                        <Form.Group className="mb-3" controlId="textArea3">
                            <Form.Label>Polje 3</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                          {...register("textArea3", {
                                              required: false,
                                              maxLength: 500
                                          })}
                            />
                            {errors.textArea3 && <Alert style={{marginTop:"0.5rem"}} variant={"danger"}>{errors.textArea3.message}</Alert>}
                        </Form.Group>
                    </Row>

                    <div className={styles.BottomButtonsWrapper}>
                        <Button type="button" onClick={clearTherapy}>Nova terapija</Button>
                        <div>
                            <div style={{display:"none"}}>
                                <ComponentToPrint ref={printRef}/>
                            </div>
                            <Button type="button" buttontype="white" style={{marginRight: "1rem"}} id={styles.PrintButton}
                                    onClick={printAction}>Štampaj terapiju</Button>
                            <Button type="submit">Zaključi terapiju</Button>
                        </div>
                    </div>
                </div>
            </Form>

            {showTherapySendConfirmationModal &&
                <TherapySendConfirmationModal
                    show={showTherapySendConfirmationModal}
                    submitTherapy={submitTherapy}
                    close={closeTherapySentConfirmationModal}
                />
            }

            {showTherapySentModal &&
            <TherapySentModal show={showTherapySentModal} />
            }
        </div>
    );
}

export default TherapyBoard;