import React, {useEffect, useState} from 'react';
import {Dropdown, Form, Modal, Spinner, Tab, Tabs} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {therapyActions} from "../../../store/therapy-slice";
import Button from "../../UI/Buttons/Button";
import styles from './AddArticleModal.module.css'
import {articleInfoActions} from "../../../store/articleInfo-slice";
import ArticleInfoModal from "../ArticleInfoModal/ArticleInfoModal";
import LoyaltyBadge from "../../UI/LoyaltyBadge/LoyaltyBadge";
import {
    fetchCategoryRelatedArticles,
    fetchRelatedArticlesOnIngredient,
    fetchRelatedArticlesOnTherapyIndication
} from "../../../store/articleInfo-actions";
import {fetchNumberOfMarketplacesForArticle} from "../../../store/therapy-actions";

const AddArticleModal = (props) => {

    const dispatch = useDispatch();
    const selectedItem = useSelector(state => state.therapy.selectedItem)
    const therapyForUpdate = useSelector(state => state.therapy.therapyForUpdate)
    const numberOfMarketplacesForArticle = useSelector(state => state.therapy.numberOfMarketplacesForArticle);
    const showArticleInfoModal = useSelector((state) => state.articleInfo.showArticleInfoModal)

    const relatedOnIngredient = useSelector(state => state.articleInfo.relatedArticlesOnIngredient);
    const relatedOnTherapyIndication = useSelector(state => state.articleInfo.relatedArticlesOnTherapyIndication);
    const categoryRelated = useSelector(state => state.articleInfo.categoryRelatedArticle);

    const loadingRelatedArticlesOnIngredient = useSelector(state => state.articleInfo.loadingRelatedArticlesOnIngredient);
    const loadingRelatedArticlesOnTherapyIndication = useSelector(state => state.articleInfo.loadingRelatedArticlesOnTherapyIndication);
    const loadingCategoryRelatedArticle = useSelector(state => state.articleInfo.loadingCategoryRelatedArticle);

    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const [itemData, setItemData] = useState({
        item: therapyForUpdate ? therapyForUpdate.item : selectedItem,
        quantity: therapyForUpdate ? therapyForUpdate.quantity : 1,
        dosing: therapyForUpdate ? therapyForUpdate.dosing : '',
        frequency: therapyForUpdate ? therapyForUpdate.frequency : '',
        duration: therapyForUpdate ? therapyForUpdate.duration : ''
    })

    useEffect(() => {
        if (selectedItem) {
            dispatch(fetchNumberOfMarketplacesForArticle(selectedItem.id));
        }

        return () => {
            dispatch(articleInfoActions.resetAllRelatedArticles())
        }
    }, [selectedItem])

    useEffect(() => {
        setItemData({
            item: therapyForUpdate ? therapyForUpdate.item : selectedItem,
            quantity: therapyForUpdate ? therapyForUpdate.quantity : 1,
            dosing: therapyForUpdate ? therapyForUpdate.dosing : '',
            frequency: therapyForUpdate ? therapyForUpdate.frequency : '',
            duration: therapyForUpdate ? therapyForUpdate.duration : ''
        })
    }, [selectedItem])

    const closeModal = () => {
        dispatch(therapyActions.toggleModal(false))
    }

    const addArticleToList = () => {
        dispatch(therapyActions.addArticleToTherapy(itemData));
        closeModal()
    }

    const editItemQuantity = (e) => {
        let temp = parseInt(e.target.value)
        setItemData({
            ...itemData,
            quantity: temp
        })
    }

    const incrementQuantity = () => {
        let temp = itemData.quantity
        setItemData({
            ...itemData,
            quantity: temp + 1
        })
    }

    const decrementQuantity = () => {
        let temp = itemData.quantity;
        if (itemData.quantity > 1) {
            setItemData({
                ...itemData,
                quantity: temp - 1
            })
        }
    }

    const setDosing = (e) => {
        setItemData({
            ...itemData,
            dosing: e.target.value
        })
    }

    const setFrequency = (e) => {
        setItemData({
            ...itemData,
            frequency: e.target.value
        })
    }

    const setDuration = (e) => {
        setItemData({
            ...itemData,
            duration: e.target.value
        })
    }

    const onClickSelect = (e) => {
        e.target.select();
    }

    const openArticleInfoModal = () => {
        dispatch(articleInfoActions.resetArticleInfo());
        dispatch(articleInfoActions.toggleArticleInfoModal());
    }

    const quickChangeArticle = (isOpen) => {
        if (isOpen) {
            if (selectedItem.jkl) {
                dispatch(fetchRelatedArticlesOnIngredient(selectedItem.id));
                dispatch(fetchRelatedArticlesOnTherapyIndication(selectedItem.id));
            } else {
                dispatch(fetchCategoryRelatedArticles(selectedItem.id));
            }
        }
        setDropdownOpen(isOpen)
    }

    const selectNewArticle = (article) => {

        dispatch(therapyActions.setSelectedItem({
            id: article.id,
            itemLoyalityPointsMedic: article.itemLoyalityPointsMedic,
            jkl: article.jkl,
            manufacturerName: article.manufacturerName,
            name: article.name,
            nameHighlighted: null,
            numberOfMarketplacesForArticle: 20,
            picture: article.pictures
        }));
        setDropdownOpen(false)
    }

    const RelatedArticles = () => {
        return (
            <Dropdown style={{margin: "0 1px"}} onToggle={quickChangeArticle} show={isDropdownOpen} align="end">
                <Dropdown.Toggle variant="success" id="dropdown-basic" className={styles.DropDownButton}>
                    Vezani artikli
                </Dropdown.Toggle>
                <Dropdown.Menu style={{maxHeight: '400px', overflowY: 'auto'}}>
                    {loadingRelatedArticlesOnIngredient || loadingRelatedArticlesOnTherapyIndication ?
                        <Spinner/> :
                        (relatedOnIngredient.length > 0 || relatedOnTherapyIndication.length > 0) &&
                        <Tabs defaultActiveKey="category1">
                            {relatedOnIngredient.length > 0 &&
                                <Tab eventKey="category1" title="Po substanci">
                                    {relatedOnIngredient.map((related, index) => {
                                        return <Dropdown.Item key={index} href="#/action-1"
                                                              onClick={() => selectNewArticle(related)}>{related.name}</Dropdown.Item>
                                    })}
                                </Tab>
                            }
                            {relatedOnTherapyIndication.length > 0 &&
                                <Tab eventKey="category2" title="Po terapijskoj indikaciji">
                                    {relatedOnTherapyIndication && relatedOnTherapyIndication.map((related, index) => {
                                        return <Dropdown.Item key={index} href="#/action-1"
                                                              onClick={() => selectNewArticle(related)}>{related.name}</Dropdown.Item>
                                    })}
                                </Tab>
                            }
                        </Tabs>
                    }
                    {loadingCategoryRelatedArticle ?
                        <Spinner/> :
                        categoryRelated.map((related, index) => {
                            return <Dropdown.Item key={index} href="#/action-1">{related.name}</Dropdown.Item>
                        })}

                </Dropdown.Menu>
            </Dropdown>
        );
    };

    return (
        <>
            <Modal
                show={props.showModal}
                onHide={closeModal}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                dialogClassName={styles.Modal}
            >
                <Modal.Header closeButton style={{backgroundColor: "#00B2B1", color: "white"}} closeVariant="white">
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Doziranje leka
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                            <div>
                                <strong style={{fontSize: "20px"}}>{itemData.item.name}</strong>
                            </div>
                            <div>{itemData.item.manufacturerName}</div>
                            <div>Broj apoteka u kojima je artikal
                                dostupan: <strong>{numberOfMarketplacesForArticle}</strong></div>
                        </div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Button className={styles.QuantityButton} onClick={decrementQuantity}>-</Button>&nbsp;
                            <Form.Control autoComplete="off"
                                          value={itemData.quantity}
                                          onChange={e => editItemQuantity(e)}
                                          className={styles.QuantityInput}
                                          type="number"
                                          onClick={onClickSelect}
                            />&nbsp;
                            <Button className={styles.QuantityButton} onClick={incrementQuantity}>+</Button>
                        </div>
                    </div>
                    <div className={styles.SubstituteArticleWrapper}>
                        <div className={styles.SubstituteArticle} onClick={openArticleInfoModal}>Više podataka o artiklu</div>
                        <div className={styles.SubstituteArticle}><RelatedArticles/></div>
                    </div>
                    <br/>
                    <Form id="addArticleForm">
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Doza</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={itemData.dosing}
                                autoComplete="off"
                                onChange={(e) => setDosing(e)}
                            />
                            <br/>
                            <Form.Label>Frekvencija</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={itemData.frequency}
                                autoComplete="off"
                                onChange={(e) => setFrequency(e)}
                            />
                            <br/>
                            <Form.Label>Trajanje</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={itemData.duration}
                                autoComplete="off"
                                onChange={(e) => setDuration(e)}
                            />
                            <br/>
                            <div
                                className={`${styles.ModalFooter} ${selectedItem.itemLoyalityPointsMedic > 0 && styles.ModalFooterNoLoyalty} `}>
                                {selectedItem.itemLoyalityPointsMedic > 0 &&
                                    <LoyaltyBadge itemLoyalityPointsMedic={selectedItem.itemLoyalityPointsMedic}
                                                  height={'36px'}
                                                  width={'36px'}
                                    />
                                }
                                <Button style={{float: "right"}} onClick={addArticleToList}>Dodaj u terapiju</Button>
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>

            {showArticleInfoModal &&
                <ArticleInfoModal show={showArticleInfoModal}
                                  article={selectedItem}
                />
            }
        </>
    );
};

export default AddArticleModal;
