import React from 'react';
import {Modal} from "react-bootstrap";
import Button from "../../UI/Buttons/Button";
import {useDispatch} from "react-redux";
import {therapyActions} from "../../../store/therapy-slice";

const TherapySentModal = (props) => {

    const dispatch = useDispatch();

    const closeModal =()=>{
        dispatch(therapyActions.toggleTherapySentModal(false))
    }


    return (
        <Modal
            size="40%"
            show={props.show}
            onHide={closeModal}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
        >
            <Modal.Header closeButton style={{backgroundColor: "#00B2B1", color: "white"}} closeVariant="white">
                <Modal.Title id="example-modal-sizes-title-lg">

                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display:"flex", flexDirection:"column", alignItems:"center", padding:"2rem" }}>

                Uspešno se kreirali i poslali terapiju.

                <Button style={{margin: "3rem auto 0"}} onClick={closeModal}>Zatvori</Button>
            </Modal.Body>
        </Modal>
    );
};

export default TherapySentModal;