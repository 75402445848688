import React from 'react';
import styles from "../DrugInfo/DrugInfo.module.css";
import {Accordion, Alert} from "react-bootstrap";

const NonDrugInfo = (props) => {
    return (
        props.article && props.article.itemInfoResponse ?
            <div className={styles.NonDrugInfoWrapper}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Opis / Namena</Accordion.Header>
                        <Accordion.Body>
                            <div style={{padding: "1rem"}}>{props.article.itemInfoResponse.description}</div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Delovanje</Accordion.Header>
                        <Accordion.Body>
                            <div style={{padding: "1rem"}}>{props.article.itemInfoResponse.effect}</div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Napomena</Accordion.Header>
                        <Accordion.Body>
                            <div style={{padding: "1rem"}}>{props.article.itemInfoResponse.note}</div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Način upoterbe</Accordion.Header>
                        <Accordion.Body>
                            <div style={{padding: "1rem"}}>{props.article.itemInfoResponse.howToUse}</div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Sastav</Accordion.Header>
                        <Accordion.Body>
                            <div style={{padding: "1rem"}}>{props.article.itemInfoResponse.containedComponents}</div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Proizvođač</Accordion.Header>
                        <Accordion.Body>
                            <div style={{padding: "1rem"}}>{props.article.manufacturerName}</div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            :
            (props.article && !props.article.itemInfoResponse &&
                <Alert variant={'danger'}>
                    Nema podataka za traženi artikal!
                </Alert>)
    );
};

export default NonDrugInfo;