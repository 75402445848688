import React from 'react';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import ArticleCard from "../ArticleCard/ArticleCard";
import styles from './CarouselComponent.module.css'

const CarouselComponent = (props) => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <div className={styles.CarouselWrapper}>
            <Carousel
                swipeable={false}
                draggable={true}
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-10-px"
            >
                {props.relatedArticles.map(article => {
                    return (
                        <ArticleCard key={article.id} article={article}/>
                    )
                })}

            </Carousel>
        </div>
    );
};

export default CarouselComponent;