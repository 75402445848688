import React, {useContext, useEffect, useState} from 'react';
import styles from './LogInPane.module.css'
import authPageStyles from '../../../pages/AuthPage/AuthPage.module.css';
import {useForm} from 'react-hook-form';
import {Form, Spinner} from "react-bootstrap";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/outline";
import AuthContext from "../../../store/context/AuthContext/auth-context";
import Button from "../../UI/Buttons/Button";


const LogInPane = (props) => {

    const currentUser = useContext(AuthContext)

    const [inputState, inputStateChanged] = useState({
        credentials: {
            email: "",
            password: ""
        }
    })

    const [showPassword, toggleShowPassword] = useState(false);

    const redirectToResetPassword = () => {
        const url = '/auth/passwordReset';
        props.history.push(url);
    }

    const [errorMessage, errorMessageHandler] = useState(null);
    if (props.loginServerError) {
        alert("error")
    }

    useEffect(() => {
        if (props.loginError || props.userInfoError) {
            errorMessageHandler(
                <div className={styles.Shake}>
                    <p className={styles.ErrorMessage}>
                        Email ili password nisu ispravni!
                    </p>
                </div>)
        }
    }, [props.loginError, props.userInfoError])

    const {register, handleSubmit, formState: {errors}, getValues} = useForm();
    const onSubmit = () => {
        const values = getValues();
        inputStateChanged({
            ...inputState,
            credentials: {
                email: values.email,
                password: values.password,
            }
        })

        currentUser.onLogin({email: values.email, password: values.password});
    }

    const resetErrorMessage = () => {
        errorMessageHandler(null);
    }

    let form = <Spinner/>
    if (!props.loadingLogin && !props.loadingUserInfo) {
        form = (
            <Form className={authPageStyles.Form} onSubmit={handleSubmit(onSubmit)}>
                <Form.Control type="text" placeholder="Email" {...register("email", {
                    required: "Ovo polje ne može biti prazno",
                    // minLength:5,
                    maxLength: 256,
                    pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Unesena adresa nije validnog formata"
                    }
                })} />
                {errors.email && <span className={authPageStyles.ErrorMessage}>{errors.email.message}</span>}

                <div style={{width: "100%", display: "flex", position: "relative", marginLeft: "10%"}}>
                    <Form.Control type={showPassword ? "text" : "password"} placeholder="Lozinka" {...register("password", {
                        required: "Ovo polje ne može biti prazno",
                        onChange: resetErrorMessage
                    })}
                    />
                    {!showPassword ?
                        <EyeSlashIcon className={styles.EyeIcon} onClick={() => toggleShowPassword(!showPassword)}/>
                        :
                        <EyeIcon className={styles.EyeIcon} onClick={() => toggleShowPassword(!showPassword)}/>
                    }
                </div>
                {errors.password && <span className={authPageStyles.ErrorMessage}>{errors.password.message}</span>}
                {errorMessage}
                <br/><br/>
                {/*<span onClick={redirectToResetPassword} style={{marginBottom: "1rem"}}>Zaboravili ste lozinku?</span>*/}
                <Button type="submit" style={{width: "200px"}}>Prijava</Button>
            </Form>
        );

    }

    return (
        <div className={styles.LogInPage}>
            {!currentUser.loadingLogin ?
                form
                :
                <div>
                    <p>Molimo sačekajte...</p>
                    <Spinner />
                </div>
            }
            <div style={{marginBottom: "20px"}}></div>
        </div>
    );
}

export default LogInPane;
