import React, {createContext, useEffect, useState} from "react";
import axios, {baseUrl} from "../../../axios/axios";
import {useDispatch} from "react-redux";
import {therapyActions} from "../../therapy-slice";
import {redirect} from "react-router-dom";

const AuthContext = createContext({
    isLoggedIn: false,
    onLogout: () => {},
    onLogin: () => {},
    currentUser: null,

    token: null,
    loginError: false,
    loginServerError: false,
    loadingLogin: false,

    loadingForgetPassword: false,
    forgetPasswordError: false,
});

export const AuthContextProvider = (props) => {
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loginUserStart, setLoginUserStart] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('id')) {
            setIsLoggedIn(true)
            fetchUserInfo()
        }
    }, [])


//login actions
    const loginStart = () => {
        setLoginUserStart(true)
    };

    const loginSuccess = (token) => {
        setLoginUserStart(false);
        return {
            // type: actionTypes.LOGIN_USER_SUCCESS,
            token: token
        };
    };

    const loginFail = () => {
        setLoginUserStart(false);
        alert("Došlo je do greške! \n Pokušajte kasnije.")

    };

    const loginRefreshFailed = () => {
        return {
            // type:actionTypes.LOGIN_REFRESH_FAILED
        };
    };

    const loginServerError = () => {
        return {
            // type:actionTypes.LOGIN_SERVER_ERROR
        };
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('email');
        localStorage.removeItem('id');
        localStorage.removeItem('firstName');
        localStorage.removeItem('secondName');
        localStorage.removeItem('roles');
        dispatch(therapyActions.removeAllArticlesAndDiagnosisFromTherapy());
        setIsLoggedIn(false);
        redirect('/auth');
    }

    const checkLoginTimeout = (expirationTime) => {
        setTimeout(() => {
            if (!(localStorage.getItem('refreshToken'))) {
                // console.log("RefreshToken")
                logout();
            }
        }, expirationTime * 1000);
    }


    const loginCheckState = () => {
        const token = localStorage.getItem('token');
        if (!token) {
            // console.log("token obican")
            logout();
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                localStorage.removeItem('token');
            } else {
                loginSuccess(token);
                userInfo({
                    id: +localStorage.getItem('id'),
                    username: localStorage.getItem('email'),
                    firstName: localStorage.getItem('firstName'),
                    secondName: localStorage.getItem('secondName'),
                    roles: localStorage.getItem('roles').split(',')
                })
                checkLoginTimeout((expirationDate.getTime() - new Date().getTime()) / 1000);
            }
        }
    }

    const login = (credentials, data = 0, googleLogin = false, refreshLogin = false) => {

        loginStart();

            //Disable for deploy
            // const url = 'http://localhost:8081/auth/oauth/token';

            //Enable for deploy
            // const url = 'https://apoteka.rs:8443/authorization_server/oauth/token';

            //Enable for deploy for dev
            const url = 'https://apoteka.rs:8443/authorization_server/oauth/token';

        const params = new URLSearchParams();
        if (refreshLogin) {
            params.append('grant_type', 'refresh_token');
            params.append('refresh_token', localStorage.getItem('refreshToken'));
        } else {
            params.append('grant_type', 'password');
            params.append('username', credentials.email);
            params.append('password', credentials.password);
        }

        axios.post(url, params, {
            auth: {
                username: 'apoteka_rs',
                password: 'F7Q8T97@a\'QveBX?'
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {

                if (response.data.userAuthorities.includes("AUTHORIZED_MEDIC_USER")) {
                    const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);

                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('refreshToken', response.data.refresh_token);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('id', response.data.userDetails.id);
                    localStorage.setItem('email', response.data.userDetails.username);
                    localStorage.setItem('firstName', response.data.userDetails.firstName);
                    localStorage.setItem('secondName', response.data.userDetails.secondName);
                    localStorage.setItem('roles', response.data.userAuthorities);
                    loginSuccess(response.data.access_token);
                    checkLoginTimeout(response.data.expires_in);
                    setIsLoggedIn(true)
                    fetchUserInfo();
                } else {
                    loginFail();
                }
            })
                .catch(error => {
                    if ( error.response !== null && error.response !== undefined) {
                        if (!refreshLogin && error.response.data.error_description === 'Bad credentials') {
                            loginFail(error.response.data);
                        } else if (refreshLogin && error.response.data.error === "invalid_token" && error.response.data.error_description.startsWith("Invalid refresh token")) {
                            loginRefreshFailed();
                            console.log("Invalid refresh token")
                            logout();
                        } else{
                            loginServerError();
                        }
                    }
                })
                .finally(() => {
                    if (data !== 0) {
                        updateUserPass({username: credentials.email, password: data});
                    }
                })
        // };
    };

    //todo proveriti da li se radi ovaj deo ili ne
    const forgetPasswordStart = () => {
        return {
            // type:actionTypes.FORGET_PASSWORD_START
        };
    };

    const forgetPasswordSuccess = () => {
        return {
            // type: actionTypes.FORGET_PASSWORD_SUCCESS
        };
    };

    const forgetPasswordFail = (error) => {
        return {
            // type:actionTypes.FORGET_PASSWORD_FAILED,
            error: error
        };
    };

    const forgetPassword = (email) => {
        forgetPasswordStart();
        const URL = '/authorization/forget_password/' + email;
        axios.get(URL)
            .then(response => {
                forgetPasswordSuccess();
                // console.log(response.data);
            })
            .catch(error => {
                console.log("Error se desio" + error);
                forgetPasswordFail(error);
            })
    }

    const updateUserPass = (userData) => {
        axios.post('/authorization/google/update', userData)
            .then(response => {
                // console.log(response);
            })
            .catch(error => {
                console.log(error);
            })
    };

    const userInfo = (userInfo) => {
        setCurrentUser(userInfo)
    };

     const fetchUserInfoStart = () => {
        return {

        };
    };

     const fetchUserInfoFailed = (error) => {
        return {
            error: error
        };
    };

    const fetchUserInfo = () => {
            fetchUserInfoStart();
            axios.get(baseUrl + '/user/' + localStorage.getItem('email'), {params: {access_token: localStorage.getItem('token')}})
                .then(response => {
                    userInfo(response.data);
                })
                .catch(error => {
                    fetchUserInfoFailed(error);
                })
        };

    return <AuthContext.Provider
        value={{
            loadingLogin: loginUserStart,
            isLoggedIn: isLoggedIn,
            onLogout: logout,
            onLogin: login,
            currentUser: currentUser,
            // token: this.token,
        }}>
        {props.children}
    </AuthContext.Provider>
}

export default AuthContext;