import React from "react"
import styles from "./Backdrop.module.css"

const backdrop = (props) => {

    return (
        props.show ? <div className={styles.Backdrop}
                          onClick={props.backdropClickHandler}
                          style={{zIndex: props.zIndex, backgroundColor: props.backgroundColor}}
        ></div> : null
    );
}

export default backdrop;
