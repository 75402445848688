import React from 'react';
import {Modal} from "react-bootstrap";
import Button from "../../UI/Buttons/Button";

const TherapySendConfirmationModal = (props) => {

    const cancelSubmit =()=>{
        props.close();
    }

    const submit =()=> {
        props.submitTherapy();
    }

    return (
        <Modal
            size="40%"
            show={props.show}
            onHide={cancelSubmit}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
        >
            <Modal.Header closeButton style={{backgroundColor: "#00B2B1", color: "white"}} closeVariant="white">
                <Modal.Title id="example-modal-sizes-title-lg">

                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display:"flex", flexDirection:"column", alignItems:"center", padding:"2rem" }}>

                Da li ste sigurni da želite da zaključite terapiju?

                <div style={{margin:"3rem auto 0", display:"flex", justifyContent:"space-between", width:"100%"}}>
                    <Button style={{width:"35%"}} onClick={cancelSubmit}>Odustani</Button>
                    <Button style={{width:"35%"}} onClick={submit}>Zaključi</Button>
                </div>
            </Modal.Body>
        </Modal>


    );
};

export default TherapySendConfirmationModal;