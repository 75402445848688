import React, {createContext} from "react";
const fuzzysort = require('fuzzysort');

const FuzzySortContext = createContext("")

export { FuzzySortContext }

const FuzzySort = (props) => {

    const sortResultsByStartWord = (results, startWord) => {
        const resultsWithStartWord = [];
        results = results.filter(result => {
            if(result.name.toLowerCase().startsWith(startWord.toLowerCase())){
                resultsWithStartWord.push(result);
            }
            else {
                return result;
            }
        });
        resultsWithStartWord.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        return resultsWithStartWord.concat(results);
    }

    //Algoritam koji proverava za svaku rec rekurzivno
    const filterByWords = (results, searchStringWords, idx) => {
        if (idx === searchStringWords.length || searchStringWords[idx] === '') {
            return results.map((articleObj) => {
                return {
                    ...articleObj.obj,
                    nameHighlighted: searchStringWords.length > 1 ? articleObj.obj.name : fuzzysort.highlight(articleObj[0], '<strong>', '</strong>'),
                    name: articleObj.obj.name
                }
            });
        }
        const obj = idx === 0 ? results : results.map((object) => object.obj);
        const threshold = idx === 0 ? -1000 : -50000;
        results = fuzzysort.go(searchStringWords[idx], obj, {keys: ['name', 'code', 'latinName'], threshold: threshold});
        idx++;
        return filterByWords(results, searchStringWords, idx);
    }

    const separateSearchStringByWords = (searchString) => {
        return searchString.split(' ').filter(string => {
            if (string.trim() !== '') {
                return string.trim();
            }
        });
    }

    const toReturn = {
        filterByWords,
        separateSearchStringByWords,
        sortResultsByStartWord,
    }


    return (
        <FuzzySortContext.Provider value={toReturn}>
            {props.children}
        </FuzzySortContext.Provider>
    )
}
export default FuzzySort