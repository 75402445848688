import React from 'react';
import {ReactComponent as PiggyIcon} from "./../../../assets/piggy-bank.svg";
import styles from "./LoyaltyBadge.module.css";

const LoyaltyBadge = (props) => {
    return (
        <div className={styles.Loyalty}
             style={{
                 height: props.height,
                 width: props.width,
             }}
        >
            <PiggyIcon className={styles.IconStyle}/>
            <div className={styles.VerticalLine}/>
            <span className={styles.LoyaltyPoints}>
                {props.itemLoyalityPointsMedic}
            </span>
        </div>
    );
};

export default LoyaltyBadge;