import React from "react";
import apotekaLogo from '../../../assets/Apoteka-logo-web.svg';
import styles from './Logo.module.css';

const Logo = () => {
    return(
        <div className={styles.Logo}>
            <img className={styles.Image} src={apotekaLogo} alt="apoteka logo"/>
        </div>
    );
}

export default Logo;