import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    fetchCategoryRelatedArticles,
    fetchRelatedArticlesOnIngredient,
    fetchRelatedArticlesOnTherapyIndication, findCategoryForArticle
} from "../../../store/articleInfo-actions";
import {Tab, Tabs} from "react-bootstrap";
import CarouselComponent from "./CarouselComponenet/CarouselComponenet";
import {articleInfoActions} from "../../../store/articleInfo-slice";

const RelatedArticles = (props) => {

    const dispatch = useDispatch()

    const relatedOnIngredient = useSelector(state => state.articleInfo.relatedArticlesOnIngredient);
    const relatedOnTherapyIndication = useSelector(state => state.articleInfo.relatedArticlesOnTherapyIndication);
    const categoryRelated = useSelector(state => state.articleInfo.categoryRelatedArticle)
    const articleCategory = useSelector( state => state.articleInfo.categoryForArticle)

    const [key, setKey] = useState('indication');
    const [articleCategoryBreadcrumbs, setArticleCategoryBreadcrumbs] = useState('')

    useEffect(() => {
        if (props.article.jkl) {
            dispatch(fetchRelatedArticlesOnIngredient(props.articleId));
            dispatch(fetchRelatedArticlesOnTherapyIndication(props.articleId));
        } else {
            dispatch(fetchCategoryRelatedArticles(props.articleId));
        }
        return() => {
            dispatch(articleInfoActions.resetAllRelatedArticles());
        }
    }, [])

    useEffect(()=> {
        if(categoryRelated) {
          dispatch(findCategoryForArticle(props.articleId));
        }
    }, [categoryRelated])

    // formiranje breadcrumbs-a za kategoriju kojoj pripada artikal
    useEffect(()=>{
        if(articleCategory && articleCategory.length > 0) {
            let tempBreadcrumbs = articleCategory.map((category, index) => {
                if (index < articleCategory.length-1) {
                    return category.name + '/ '
                } else if (index === articleCategory.length-1) {
                    return category.name
                }
            });
            setArticleCategoryBreadcrumbs('Artikli iz iste kategorije (' + tempBreadcrumbs.join('') +')')
        }
    }, [articleCategory])

    return (
        (relatedOnIngredient || relatedOnTherapyIndication || categoryRelated) &&
        <Tabs
            id="controlled-tab-example"
            activeKey={categoryRelated.length > 0 ? 'category' : key}
            onSelect={(k) => setKey(k)}
            className="mt-3"
        >
            {props.article.jkl && relatedOnTherapyIndication && relatedOnTherapyIndication.length > 0 &&
            <Tab eventKey="indication" title="Artikli povezani po terapijskoj indikaciji">
                {relatedOnTherapyIndication && relatedOnTherapyIndication.length > 0 ?
                <CarouselComponent relatedArticles={relatedOnTherapyIndication}/>
                    :
                    <div>Nema podataka o povezanim artiklima.</div>
                }
            </Tab>
            }
            {props.article.jkl && relatedOnIngredient && relatedOnIngredient.length > 0 &&
            <Tab eventKey="ingredient" title="Artikli bazirani na istoj supstanci">
                {relatedOnIngredient && relatedOnIngredient.length > 0 ?
                <CarouselComponent relatedArticles={relatedOnIngredient}/>
                    :
                    <div>Nema podataka o povezanim artiklima.</div>
                }
            </Tab>
            }
            {categoryRelated.length > 0 &&
            <Tab eventKey="category" title={articleCategoryBreadcrumbs}>
                {categoryRelated && categoryRelated.length > 0 ?
                    <CarouselComponent relatedArticles={categoryRelated}/>
                    :
                    <div>Nema podataka o povezanim artiklima.</div>
                }
            </Tab>
            }
        </Tabs>
    );

};

export default RelatedArticles;