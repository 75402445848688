import React, {useContext} from 'react';
import AuthContext from "../../../store/context/AuthContext/auth-context";
import ProgressBar from "@ramonak/react-progress-bar";
import styles from "./MyLoyalty.module.css";
import {ReactComponent as PiggyIcon} from "./../../../assets/piggy-bank.svg";

const MyLoyalty = () => {

    const currentUser = useContext(AuthContext);

    const userLoyaltyPoints = currentUser.currentUser.userLoyalityPoints;

    return (
        <div>
            <h4>Loyalty program</h4>
            <p> Ovde su prikazani tvoji trenutni Loyalty bodovi, koje možeš da iskoristiš u svakom momentu.
                Hvala što si član apoteka.rs porodice i što brigu o tebi i tvojim najbližima deliš sa nama. </p>
            <div>Ostvareno loyalty bodova: {userLoyaltyPoints}</div>
            <div className={styles.ProgressWrap}>
                <div className={styles.LevelWrapper} style={{top: "-17px", left: "-25px"}}>
                    <PiggyIcon className={styles.PiggyIconStyle}/>
                </div>
                <ProgressBar completed={userLoyaltyPoints}
                             maxCompleted={200}
                             width="220px"
                             height="12px"
                             bgColor="#FFAE00"
                             baseBgColor="#D0D4D9"
                             isLabelVisible={false}
                             margin="6px"
                />
                <div className={styles.LevelWrapper}
                     style={{top: "-17px", left: "200px"}}>
                    {userLoyaltyPoints < 200 ? <PiggyIcon className={styles.PiggyIconStyleGray}/> :
                        <PiggyIcon className={styles.PiggyIconStyle}/>}
                </div>
                <ProgressBar completed={userLoyaltyPoints - 200}
                             maxCompleted={2000}
                             width="220px"
                             height="12px"
                             bgColor="#FFAE00"
                             baseBgColor="#D0D4D9"
                             isLabelVisible={false}
                             margin="6px"
                />
                <div className={styles.LevelWrapper} style={{top: "-17px", right: "-20px"}}>
                    {userLoyaltyPoints < 2000 ? <PiggyIcon className={styles.PiggyIconStyleGray}/> : <PiggyIcon className={styles.PiggyIconStyle}/>}
                </div>
            </div>
            <div className={styles.LevelLabelWrapper}>
                <span className={styles.LevelLabelText} style={{color: "#FFAE00"}}>Nivo 1</span>
                <span className={styles.LevelLabelText}
                      style={userLoyaltyPoints >= 200 ? {color: "#FFAE00"} : null}>Nivo 2</span>
                <span className={styles.LevelLabelText}
                      style={userLoyaltyPoints >= 2000 ? {color: "#FFAE00"} : null}>Nivo 3</span>
            </div>
        </div>
    );
};

export default MyLoyalty;
