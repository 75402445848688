import React from 'react';
import {useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";
import DrugInfo from "./DrugInfo/DrugInfo";
import NonDrugInfo from "./NonDrugInfo/NonDrugInfo";
import styles from './ArticleInformation.module.css'

const ArticleInformation = (props) => {

    const loadingArticleInfo = useSelector(state => state.articleInfo.loadingArticleInfo)
    const articleInfo = useSelector((state) => state.articleInfo.articleInfo)

    return (
        <div className={styles.ArticleInformationModalContent}>
            {loadingArticleInfo &&
                <div className={styles.Spinner}>
                    <div>Molimo sačekajte...</div>
                    <Spinner/>
                </div>
            }

            {props.articleJKL ?
                <DrugInfo article={articleInfo && articleInfo.extraData}/> :
                <NonDrugInfo article={articleInfo} />
            }

        </div>
    );
};

export default ArticleInformation;