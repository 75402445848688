import React, {useContext} from "react";
import {Navbar, NavDropdown} from "react-bootstrap";
import Logo from "./Logo/Logo";
import AuthContext from "../../store/context/AuthContext/auth-context";
import {useNavigate} from "react-router-dom";
import {ArrowRightOnRectangleIcon, CalendarDaysIcon, Cog6ToothIcon, UserIcon} from "@heroicons/react/24/outline";
import {ReactComponent as PiggyIcon} from "./../../assets/piggy-bank.svg";
import styles from './HeaderComponent.module.css';
import {useDispatch} from "react-redux";
import {profileActions} from "../../store/profile-slice";

const HeaderComponent = () => {

    const currentUser = useContext(AuthContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoutHandler = () => {
        currentUser.onLogout();
        navigate("/auth/login");
    }

    const openProfileModal = () => {
        dispatch(profileActions.toggleProfileModal(true));
        dispatch(profileActions.setActiveLink('my-profile'));
    }

    const openLoyaltyModal = () => {
        dispatch(profileActions.toggleProfileModal(true));
        dispatch(profileActions.setActiveLink('loyalty'))
    }

    return (
        <>
            <div>
                <Navbar>
                    <Navbar.Brand href="#home"><Logo/></Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <NavDropdown title={<Cog6ToothIcon className={styles.MenuIcon}/>} id="nav-dropdown" align="end">
                            <NavDropdown.Header>{currentUser.currentUser &&
                                <>{currentUser.currentUser.firstName} {currentUser.currentUser.secondName}</>}
                            </NavDropdown.Header>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item className={styles.DropdownItem} onClick={openProfileModal}>
                                <UserIcon className={styles.IconStyle}/><span>Moj Profil</span>
                            </NavDropdown.Item>
                            <NavDropdown.Item className={styles.DropdownItem} onClick={openLoyaltyModal}>
                                <PiggyIcon className={styles.IconStyle}/>Loyalti program
                            </NavDropdown.Item>
                            <NavDropdown.Item className={styles.DropdownItem} disabled>
                                <CalendarDaysIcon className={styles.IconStyle}/>Kalendar
                            </NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item className={styles.DropdownItem} onClick={logoutHandler}><ArrowRightOnRectangleIcon
                                className={styles.IconStyle}/>Odjavi se</NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </>
    );
}

export default HeaderComponent;