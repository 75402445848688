import React from 'react';
import styles from './ArticleGeneralInfo.module.css';
import ArticlePicture from "./ArticlePicture/ArticlePicture";
import ArticleData from "./ArticleData/ArticleData";

const ArticleGeneralInfo = (props) => {

    return (
        <div className={styles.ArticleGeneralInfoWrapper}>
            <ArticlePicture articlePicture={props.article.picture} />
            {/*<ArticlePicture articlePicture={drugArticle ? drugArticle.pictures : nonDrugArticle.pictures} />*/}
            <ArticleData article={props.article}/>
        </div>
    );
};


export default ArticleGeneralInfo;