import React from 'react';
import styles from './FooterComponent.module.css';
import Logo from "../HeaderComponent/Logo/Logo";

const FooterComponent = () => {
    return (
        <footer className={styles.FooterWrapper}>
            <div className={styles.Logo}>
                <Logo/>
            </div>
            <div style={{margin:"auto 0"}}>
                <span style={{color:"#4D5055"}}>Copyright © 2022 apoteka.rs</span>
            </div>
        </footer>
    );
};

export default FooterComponent;
