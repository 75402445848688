import './App.css';
import TherapyPage from "./pages/TherapyPage/TherapyPage";
import AuthPage from "./pages/AuthPage/AuthPage";
import {Route, Routes, useNavigate} from "react-router-dom";
// import ForgotPasswordPane from "./components/AuthComponents/ForgotPasswordPane/ForgotPasswordPane";
// import ResetPasswordPane from "./components/AuthComponents/ResetPasswordPane/ResetPasswordPane";
import React, {useContext, useEffect} from "react";
import AuthContext from "./store/context/AuthContext/auth-context";

function App() {

    const currentUser = useContext(AuthContext)
    const navigate = useNavigate();

    useEffect(() => {
        // console.log(currentUser);

        if (currentUser.isLoggedIn) {
            navigate('/therapy')
        } else {
            navigate('/auth');
        }
    }, [currentUser, navigate])

    let routes = (
        !currentUser.isLoggedIn
            ?
            <Routes>
                {/*<Route path="/auth/passwordReset" component={<ForgotPasswordPane/>}/>*/}
                {/*<Route path="/auth/forget_password/:email/:token" component={<ResetPasswordPane/>}/>*/}
                <Route path="/auth/*" element={<AuthPage/>}/>
            </Routes>
            :
            <Routes>
                <Route path="/therapy" element={<TherapyPage/>}/>
            </Routes>
    );

    return (
        <div className="App">
            {routes}
        </div>
    );
}

export default App;