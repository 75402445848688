import React from 'react';
import {Card} from "react-bootstrap";
import ConfigData from "../../../../config.json";
import DefaultImage from "../../../../assets/PictureHolder.png"
import AvailabilityBadge from "../../../UI/AvailabilityBadge/AvailabilityBadge";
import LoyaltyBadge from "../../../UI/LoyaltyBadge/LoyaltyBadge";
import styles from './ArticleCard.module.css'
import {useDispatch} from "react-redux";
import {fetchNonDrugInfo} from "../../../../store/articleInfo-actions";
import {fetchNumberOfMarketplacesForArticle} from "../../../../store/therapy-actions";

const ArticleCard = (props) => {

    const dispatch = useDispatch();

    const changeArticle = () => {
        dispatch(fetchNonDrugInfo(props.article.id));
        dispatch(fetchNumberOfMarketplacesForArticle(props.article.id));
    }

    return (
        <Card className={styles.Card} onClick={() => changeArticle(props.article.id)}>

            <Card.Img variant="top"
                      src={props.article.pictures ? ConfigData.ArticleImageLink + props.article.pictures : DefaultImage}/>
            <Card.Body>
                <Card.Title>{props.article.name}</Card.Title>
                <Card.Text>
                    {props.article.manufacturerName}
                </Card.Text>
                <Card.Footer className="d-lg-inline-flex j-spa justify-content-between" style={{
                    backgroundColor: "transparent",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    width: "100%"
                }}>
                    <AvailabilityBadge
                        itemAvailabilityColor={props.article.itemAvailabilityColor}
                    >
                        {props.article.itemAvailabilityShortCode}
                    </AvailabilityBadge>
                    {props.article.itemLoyalityPointsMedic > 0 &&
                        <LoyaltyBadge itemLoyalityPointsMedic={props.article.itemLoyalityPointsMedic}
                                      height={'26px'}
                                      width={'66px'}
                        />
                    }
                </Card.Footer>
            </Card.Body>
        </Card>
    );
};

export default ArticleCard;