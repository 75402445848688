import React, {useEffect, useRef, useState} from "react";
import SearchItem from "./SearchItem/SearchItem";
import styles from './SearchItems.module.css';

const SearchItems = (props) => {


    const useKeyPress = function (targetKey) {

        const [keyPressed, setKeyPressed] = useState(false)

        const downHandler = ({key}) => {
            if(key === targetKey) {
                setKeyPressed(true)
            }
        }

        const upHandler = ({key}) => {
            if(key === targetKey) {
                setKeyPressed(false)
            }
        }

        useEffect(()=>{
            window.addEventListener('keydown', downHandler);
            window.addEventListener('keyup', upHandler);

            return () => {
                window.removeEventListener("keydown", downHandler);
                window.removeEventListener("keyup", upHandler);
            };
        })

        return keyPressed
    };

    const [selected, setSelected] = useState(undefined);
    const downPress = useKeyPress("ArrowDown");
    const upPress = useKeyPress("ArrowUp");
    const enterPress = useKeyPress("Enter");
    const [cursor, setCursor] = useState(0);
    const [hovered, setHovered] = useState(undefined);
    const [items, setItems] = useState([])

    const fieldRef = useRef(null)

    useEffect(() => {
        if(items) {
            setItems([])

            props.results && props.results.map(res => {
                setItems(prev => [
                    ...prev,
                    res
                ])
            })
        }
    }, [props.results])


    useEffect(()=>{
        if (cursor && fieldRef) {
            fieldRef.current.scrollIntoView({
                behavior: "smooth",
                block: 'nearest',
                inline: 'start'
            });
        }
    }, [cursor])

    const ListItem = ({ item, active }) => (
        <div
            className={`${active ? styles.ItemActive : ""}`}
            ref={active ? fieldRef : null}
        >
            <SearchItem item={item} diagnosis={props.diagnosis} close={props.close} enter={selected}/>
        </div>
    );

    useEffect(() => {
        if (items && items.length && downPress) {
            setCursor(prevState =>
                prevState < items.length - 1 ? prevState + 1 : prevState
            );
        }
    }, [downPress]);

    useEffect(() => {
        if (items && items.length && upPress) {
            setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
        }
    }, [upPress]);

    useEffect(() => {
        if (items && items.length && enterPress) {
            setSelected(items[cursor]);
        }
    }, [cursor, enterPress]);

    useEffect(() => {
        if (items && items.length && hovered) {
            setCursor(items.indexOf(hovered));
        }
    }, [hovered]);


    return (
            <div className={`${styles.SearchItems} ${props.className}`} style={props.style} onClick={props.onClick}>
                {items && items.map((item, i) => {
                    return (
                    <ListItem
                        key={i}
                        active={i === cursor}
                        item={item}
                        setSelected={setSelected}
                        setHovered={setHovered}
                    />
                    )
                })
                }
                {/*{props.results &&*/}
                {/*    props.results.map(res => (*/}
                {/*        <SearchItem key={res.id} item={res} diagnosis={props.diagnosis} close={props.close}/>*/}
                {/*    ))}*/}
            </div>
    );
}

export default SearchItems;