import axios from "axios";

//Enable for dev
export const baseUrl = 'https://apoteka.rs:8443/apoteka_rs_server';

//Enable for deploy
// export const baseUrl = 'https://apoteka.rs:8443/apoteka_rs_server';


const instance = axios.create({
    baseURL: baseUrl,
    // withCredentials:true,
    // 'Access-Control-Allow-Credentials':true,
    // headers:  {"Access-Control-Allow-Origin": "*"}
});

// instance.defaults.params = {};
instance.interceptors.request.use(function (config) {
    // config.params['access_token'] = localStorage.getItem('token');
    const token = localStorage.getItem("token");
    if (token !== null) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
// instance.interceptors.response.use(onResponse, onResponseError);

export default instance;