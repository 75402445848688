import React from 'react';
import ArticleGeneralInfo from "./ArticleGeneralInfo/ArticleGeneralInfo";
import RelatedArticles from "./RelatedArticles/RelatedArticles";
import ArticleInformation from "./ArticleInformation/ArticleInformation";
import {useSelector} from "react-redux";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import styles from './ArticleDetails.module.css'

const ArticleDetails = (props) => {

    const articleInfo = useSelector(state => state.articleInfo.articleInfo);
    const loadingArticleInfo = useSelector(state => state.articleInfo.loadingArticleInfo)

    return (
        <Container fluid={true} className={styles.ArticleDetailsContainer}>
            {loadingArticleInfo &&
                <div className="w-100 mt-5 d-flex flex-column justify-content-center align-items-center">
                    <div>Molimo, sačekajte...</div>
                    <Spinner/>
                </div>
            }
            {!loadingArticleInfo &&
                <>
                    <div className="m-3 fw-bold fs-4">{articleInfo && articleInfo.name}</div>
                    <Row lg={12} xl={12}>
                        <Col lg={4}>
                            <ArticleGeneralInfo article={props.article}/>
                        </Col>
                        <Col lg={8}>
                            <ArticleInformation articleJKL={props.article.jkl}/>
                            <RelatedArticles article={props.article} articleId={props.article.id}/>
                        </Col>
                    </Row>
                </>
            }
        </Container>
    );
};

export default ArticleDetails;