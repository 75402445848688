import React from 'react';
import styles from "./ArticleItem.module.css";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {useDispatch} from "react-redux";
import {therapyActions} from "../../../../store/therapy-slice";

const ArticleItem = (props) => {

    const dispatch = useDispatch();

    const openUpdateModal =()=> {
        dispatch(therapyActions.toggleModal(true))
        dispatch(therapyActions.updateTherapy(props.article))
    }

    return (
        <>
            <div className={styles.ArticleInTherapyWrapper}>
                <div className={styles.UpperPart}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <strong>{props.article.item.name}</strong>&nbsp;
                        {!props.forPrint &&
                        <PencilSquareIcon
                            width="24px"
                            style={{stroke: '#00B2B1', cursor: "pointer"}}
                            onClick={openUpdateModal}
                        />}
                    </div>
                    {!props.forPrint ?
                        <XMarkIcon className={styles.IconStyle} onClick={props.removeItem}/>
                        :
                        <div style={{fontWeight:"bold"}}>x{props.article.quantity}</div>
                    }
                </div>
                <div className={styles.BottomPart}>
                    {!props.forPrint && <div><span className={styles.InfoLabel}>Količina:</span> {props.article.quantity}</div>}
                    <div><span className={styles.InfoLabel}>Trajanje terapije:</span> {props.article.duration ? props.article.duration : '/'}</div>
                    <div><span className={styles.InfoLabel}>Doziranje:</span> {props.article.dosing ? props.article.dosing : '/'}</div>
                    <div><span className={styles.InfoLabel}>Frekvencija:</span> {props.article.frequency ? props.article.frequency : '/'}</div>
                </div>
            </div>
        </>

    );
};

export default ArticleItem;