import React, {useContext} from 'react';
import {Col, Container, Modal, Nav, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {profileActions} from "../../store/profile-slice";
import styles from './MyProfilePage.module.css';
import MyProfileSettings from "../../components/MyProfileComponents/MyProfileSettings/MyProfileSettings";
import MyLoyalty from "../../components/MyProfileComponents/MyLoyalty/MyLoyalty";
import AuthContext from "../../store/context/AuthContext/auth-context";

const MyProfilePage = (props) => {

    const activeLink = useSelector((state) => state.profile.activeLink);
    const dispatch = useDispatch();

    const currentUser = useContext(AuthContext);

    const closeModal = () => {
        dispatch(profileActions.toggleProfileModal(false))
    }

    const handleLinkClick = (link) => {
        dispatch(profileActions.setActiveLink(link));
    };

    return (
        <Modal show={props.showModal} onHide={closeModal} fullscreen={true} className="mx-auto">
            <Modal.Header closeButton>
                <Modal.Title className={styles.ModalTitle}>
                    <span style={{fontSize: "12px"}}>Korisnik</span>
                    <span style={{fontSize: "16px"}}>{currentUser.currentUser.firstName} {currentUser.currentUser.secondName}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Nav className="flex-column mx-auto">
                    <Container style={{maxWidth:"900px"}}>
                        <div className={styles.SettingsTitle}>Podešavanja</div>
                        <Row>
                            <Col lg={3} md={3} xs={3} >
                                <Nav.Link className="px-0" onClick={() => handleLinkClick("my-profile")}>Moj profil</Nav.Link>
                                <Nav.Link className="px-0" onClick={() => handleLinkClick("loyalty")}>Loyalty program</Nav.Link>
                                <Nav.Link className="px-0" disabled>Kalendar</Nav.Link>
                            </Col>
                            <Col>
                                {activeLink === 'my-profile' && <MyProfileSettings/>}
                                {activeLink === 'loyalty' && <MyLoyalty/>}
                            </Col>
                        </Row>
                    </Container>
                </Nav>
            </Modal.Body>
        </Modal>
    );
};

export default MyProfilePage;