import React from 'react'
import styles from './Button.module.css'

const Button = (props) => {

    let button;
    switch (props.buttontype) {
        case ('blue'):
            button = <button
                className={`${styles.Button} ${styles.BlueButton}`}
                {...props}/>
            break;
        case ('white'):
            button = <button
                className={`${styles.Button} ${styles.WhiteButton}`}
                {...props}/>
            break;

        default:
            button = <button
                className={`${styles.Button} ${styles.BlueButton}`}
                {...props}/>
    }

    return (
        <>
            {button}
        </>
    );

}

export default Button