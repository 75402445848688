import axios from './../axios/axios'
import {articleInfoActions} from "./articleInfo-slice";

export const fetchNonDrugInfo = (articleId) => {
    return dispatch => {
        dispatch(articleInfoActions.fetchArticleInfoStart());
        const URL = '/item/' + articleId;
        let tempRes

        return axios.get(URL)
            .then(response => {
                // Dispatch first response for drug item (jkl)
                tempRes = response.data
                dispatch(articleInfoActions.fetchArticleInfoSuccess(tempRes));

                // Call fetchDrugInfo and wait for its response
                if(tempRes.jkl) {
                    return dispatch(fetchDrugInfo(+tempRes.jkl));
                }
            })
            .then(response => {
                // Combine the data from both responses and dispatch it
                if(response) {
                    dispatch(articleInfoActions.fetchArticleInfoSuccess({
                        ...tempRes, // spreading first response
                        extraData: response.data // adding second response
                    }));
                }
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(articleInfoActions.fetchArticleInfoFailed());
            });
    };
};

export const fetchDrugInfo = (articleJKL) => {
    return dispatch => {
        dispatch(articleInfoActions.fetchArticleInfoStart());
        const URL = '/lek_info/' + articleJKL;

        return axios.get(URL)
            .then(response => {
                dispatch(articleInfoActions.fetchArticleInfoSuccess(response.data));
                // console.log(response.data);
                // Return the response data along with any additional data you want to include
                return { extraData: '', data: response.data };
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(articleInfoActions.fetchArticleInfoFailed());
            });
    };
};

export const fetchRelatedArticlesOnIngredient = (articleId) => {
    return dispatch => {
        dispatch(articleInfoActions.fetchRelatedArticlesOnIngredientStart());
        const URL = '/related_item/ingredient/' + articleId;
        axios.get(URL)
            .then(response => {
                // console.log(response.data);
                dispatch(articleInfoActions.relatedArticlesOnIngredientSuccess(response.data))
            })
            .catch(error => {
                dispatch(articleInfoActions.fetchRelatedArticlesOnIngredientFailed())
            })
    };
}

export const fetchRelatedArticlesOnTherapyIndication = (articleId) => {
    return dispatch => {
        dispatch(articleInfoActions.fetchRelatedArticlesOnTherapyIndicationStart());
        const URL = '/related_item/therapy_indication/' + articleId;
        axios.get(URL)
            .then(response => {
                // console.log(response.data);
                dispatch(articleInfoActions.relatedArticlesOnTherapyIndicationSuccess(response.data))
            })
            .catch(error => {
                dispatch(articleInfoActions.fetchRelatedArticlesOnTherapyIndicationFailed())
            })
    };
}

export const fetchCategoryRelatedArticles = (articleId) => {
    return dispatch => {
        dispatch(articleInfoActions.fetchCategoryRelatedArticlesStart());
        const URL = '/related_item/group/' + articleId;
        axios.get(URL)
            .then(response => {
                // console.log(response.data)
                dispatch(articleInfoActions.fetchCategoryRelatedArticlesSuccess(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(articleInfoActions.fetchCategoryRelatedArticlesFailed())

            })
    };
}

export const findCategoryForArticle = (articleId) => {
    return dispatch => {
        dispatch(articleInfoActions.findCategoryForArticleStart());
        const URL = '/item/item_groups/' + articleId;
        axios.get(URL)
            .then(response => {
                // console.log(response.data)
                dispatch(articleInfoActions.findCategoryForArticleSuccess(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(articleInfoActions.findCategoryForArticleFailed())
            })
    };
}