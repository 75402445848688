import React from 'react';
import styles from './AvailabilityBadge.module.css'

const AvailabilityBadge = (props) => {
    return (
        <div className={styles.AvailabilityBadge}
             style={{
                 backgroundColor: props.itemAvailabilityColor,
                 marginRight: props.marginRight
             }}
        >
            {props.children}
        </div>
    );
};

export default AvailabilityBadge;