import React from 'react';
import styles from './AuthPage.module.css'
import LogInPane from "../../components/AuthComponents/LogInPane/LogInPane";
import logo from '../../assets/Apoteka-logo-web.svg';

const AuthPage = () => {

    return (
        <div className={styles.AuthPageContent}>
            <div className={styles.TopContainer}>
                <img src={logo} alt="apotekaLogo" className={styles.AuthPageLogo}/>
                <br/>
            </div>

            <div className={styles.Links}>
                <div className={styles.LoginPaneWrapper}>
                    <LogInPane/>
                </div>
            </div>
        </div>
    );
}

export default AuthPage;