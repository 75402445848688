import React, {useContext} from 'react';
import AuthContext from "../../../store/context/AuthContext/auth-context";
import styles from './MyProfileSettings.module.css';

const MyProfileSettings = () => {

    const currentUser = useContext(AuthContext);

    return (
        <div className={styles.MyProfileSettings}>
            <span>Podaci o korisniku</span>
            <span>Ime i prezime: {currentUser.currentUser.firstName} {currentUser.currentUser.secondName}</span>
            <span>Broj licence: {currentUser.currentUser.lbo}</span>
        </div>
    );
};

export default MyProfileSettings;
