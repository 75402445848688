import React from "react";
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import TherapyBoard from "../../components/TherapyBoard/TherapyBoard";
import styles from "./TherapyPage.module.css";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import {useSelector} from "react-redux";
import MyProfilePage from "../MyProfilePage/MyProfilePage";

const TherapyPage = () => {

    const profileModalOpen = useSelector(state => state.profile.profileModalOpen)

    return (
        <div className={styles.TherapyPage}>
            <div className={styles.UpperPartWrapper}>
                <HeaderComponent/>
                <TherapyBoard/>
            </div>
            <FooterComponent/>

            {profileModalOpen && <MyProfilePage showModal={profileModalOpen}/>}
        </div>
    );
}

export default TherapyPage;