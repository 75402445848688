import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import ArticleItem from "./ArticleItem/ArticleItem";
import {therapyActions} from "../../../store/therapy-slice";

const ArticlesList = (props) => {

    const dispatch = useDispatch();
    const allArticles = useSelector(state => state.therapy.articles)

    const removeItem =(articleId)=> {
        dispatch(therapyActions.removeArticleFromTherapy(articleId))
    }

    return (
        <div>
            {allArticles && allArticles.map(article =>
                <ArticleItem
                    key={article.item.id}
                    article={article}
                    removeItem={()=>removeItem(article.item.id)}
                    forPrint={props.forPrint}
                />
            )}
        </div>
    );
};

export default ArticlesList;