import React from 'react';
import styles from './DiagnosisItem.module.css';
import {XMarkIcon} from "@heroicons/react/20/solid";

const DiagnosisItem = (props) => {
    return (
        <div className={styles.DiagnosisItem}>
            <span> <span className={styles.DiagnosisCode}>{props.item.code}</span> - {props.item.latinName ? props.item.latinName : props.item.name} </span>
            <XMarkIcon className={styles.IconStyle} onClick={props.removeItem}/>
        </div>
    );
};

export default DiagnosisItem;