import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    showArticleInfoModal: false,

    loadingArticleInfo: false,
    articleInfo: null,
    articleInfoFailed: false,

    loadingRelatedArticlesOnIngredient: false,
    relatedArticlesOnIngredient: [],
    fetchRelatedArticlesOnIngredientFailed: false,

    loadingRelatedArticlesOnTherapyIndication: false,
    relatedArticlesOnTherapyIndication: [],
    fetchRelatedArticlesOnTherapyIndicationFailed: false,

    loadingCategoryRelatedArticle: false,
    categoryRelatedArticle: [],
    fetchCategoryRelatedArticlesFailed: false,

    loadingFindCategoryForArticle: false,
    categoryForArticle: null,
    fetchFindCategoryForArticleFailed: false

}

const articleInfoSlice = createSlice({
    name: 'articleInfo',
    initialState: initialState,
    reducers: {
        toggleArticleInfoModal: (state) => {
            state.showArticleInfoModal = !state.showArticleInfoModal
        },
        fetchArticleInfoStart: (state) => {
            state.loadingArticleInfo = true;
            state.articleInfoFailed = false;
        },
        fetchArticleInfoSuccess: (state, action) => {
            state.articleInfo = action.payload;
            state.loadingArticleInfo = false;
            state.articleInfoFailed = false;
        },
        fetchArticleInfoFailed: (state) => {
            state.loadingArticleInfo = false;
            state.articleInfo = null;
            state.articleInfoFailed = false;
        },
        resetArticleInfo: (state) => {
            state.loadingArticleInfo = false;
            state.articleInfo = null;
            state.articleInfoFailed = false;
        },
        fetchRelatedArticlesOnIngredientStart: (state) => {
            state.loadingRelatedArticlesOnIngredient = true;
            state.relatedArticlesOnIngredient = [];
            state.fetchRelatedArticlesOnIngredientFailed = false;
        },
        relatedArticlesOnIngredientSuccess: (state, action) => {
            state.loadingRelatedArticlesOnIngredient = false;
            state.relatedArticlesOnIngredient = action.payload;
            state.fetchRelatedArticlesOnIngredientFailed = false;
        },
        fetchRelatedArticlesOnIngredientFailed: (state) => {
            state.loadingRelatedArticlesOnIngredient = false;
            state.relatedArticlesOnIngredient = [];
            state.fetchRelatedArticlesOnIngredientFailed = true;
        },
        fetchRelatedArticlesOnTherapyIndicationStart: (state) => {
            state.loadingRelatedArticlesOnTherapyIndication = true;
            state.relatedArticlesOnTherapyIndication = [];
            state.fetchRelatedArticlesOnTherapyIndicationFailed = false;
        },
        relatedArticlesOnTherapyIndicationSuccess: (state, action) => {
            state.loadingRelatedArticlesOnTherapyIndication = false;
            state.relatedArticlesOnTherapyIndication = action.payload;
            state.fetchRelatedArticlesOnTherapyIndicationFailed = false;
        },
        fetchRelatedArticlesOnTherapyIndicationFailed: (state) => {
            state.loadingRelatedArticlesOnTherapyIndication = false;
            state.relatedArticlesOnTherapyIndication = [];
            state.fetchRelatedArticlesOnTherapyIndicationFailed = true;
        },
        fetchCategoryRelatedArticlesStart: (state) => {
            state.loadingCategoryRelatedArticle = true;
            state.categoryRelatedArticle = [];
            state.fetchCategoryRelatedArticlesFailed = false;
        },
        fetchCategoryRelatedArticlesSuccess: (state, action) => {
            state.loadingCategoryRelatedArticle = false;
            state.categoryRelatedArticle = action.payload;
            state.fetchCategoryRelatedArticlesFailed = true;
        },
        fetchCategoryRelatedArticlesFailed: (state) => {
            state.loadingCategoryRelatedArticle = false;
            state.categoryRelatedArticle = [];
            state.fetchCategoryRelatedArticlesFailed = false;
        },
        findCategoryForArticleStart: (state) => {
            state.loadingFindCategoryForArticle = true;
            state.categoryForArticle = null;
            state.fetchFindCategoryForArticleFailed = false;
        },
        findCategoryForArticleSuccess: (state, action) => {
            state.loadingFindCategoryForArticle = false;
            state.categoryForArticle = action.payload;
            state.fetchFindCategoryForArticleFailed = false;
        },
        findCategoryForArticleFailed: (state) => {
            state.loadingFindCategoryForArticle = false;
            state.categoryForArticle = null;
            state.fetchFindCategoryForArticleFailed = true;
        },
        resetAllRelatedArticles: (state) => {
            state.categoryRelatedArticle = [];
            state.relatedArticlesOnIngredient = [];
            state.relatedArticlesOnTherapyIndication = [];
        }
    }
});

export const articleInfoActions = articleInfoSlice.actions

export default articleInfoSlice;