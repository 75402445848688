import React, {useEffect} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {articleInfoActions} from "../../../store/articleInfo-slice";
import ArticleDetails from "../../ArticleDetails/ArticleDetails";
import {fetchNonDrugInfo} from "../../../store/articleInfo-actions";
import {therapyActions} from "../../../store/therapy-slice";
import {fetchNumberOfMarketplacesForArticle} from "../../../store/therapy-actions";

const ArticleInfoModal = (props) => {

    const dispatch = useDispatch();

    const articleInfo = useSelector((state) => state.articleInfo.articleInfo);

    useEffect(() => {
        dispatch(fetchNonDrugInfo(props.article.id))
    }, [props.article])

    const handleClose = () => {
        dispatch(articleInfoActions.toggleArticleInfoModal())
        dispatch(fetchNumberOfMarketplacesForArticle(props.article.id))
    }

    const useThisArticle = () => {
            dispatch(therapyActions.setSelectedItem({
                id: articleInfo.id,
                itemLoyalityPointsMedic: articleInfo.itemLoyalityPointsMedic,
                jkl: articleInfo.jkl,
                manufacturerName: articleInfo.manufacturerName,
                name: articleInfo.name,
                nameHighlighted: null,
                numberOfMarketplacesForArticle: 20,
                picture: articleInfo.pictures
            }));
        dispatch(articleInfoActions.toggleArticleInfoModal())
    }

    return (
        <Modal show={props.show}
               onHide={handleClose}
               {...props}
               fullscreen
               centered
        >
            <Modal.Header closeButton style={{backgroundColor: "#00B2B1", color: "white"}} closeVariant="white">
                <Modal.Title>Informacije o izabranom artiklu</Modal.Title>
            </Modal.Header>

            <Modal.Body className="overflow-auto" style={{backgroundColor:"#fbfbfb"}}>
              <ArticleDetails article={props.article}/>
            </Modal.Body>

            <Modal.Footer className="mb-auto">
                <Button variant="secondary" onClick={handleClose}>Zatvori</Button>
                <Button variant="primary" onClick={useThisArticle}>Zameni artikal</Button>
            </Modal.Footer>


        </Modal>
    );
};

export default ArticleInfoModal;