import React, {useEffect} from 'react';
import {PlusIcon} from "@heroicons/react/20/solid";
import styles from './SearchItem.module.css';
import {useDispatch} from "react-redux";
import therapySlice, {therapyActions} from "../../../../../store/therapy-slice";

const SearchItem = (props) => {

    const dispatch = useDispatch();

    const clickItemHandler = () => {
        if (props.diagnosis) {
            dispatch(therapyActions.addDiagnosis(props.item));
            props.close();
        } else {
            dispatch(therapySlice.actions.setSelectedItem(props.item))
            props.close();
            dispatch(therapyActions.toggleModal(true))
            dispatch(therapyActions.updateTherapy(null))
        }
    }

    useEffect(()=>{
        if(props.enter) {
            if (props.diagnosis) {
                dispatch(therapyActions.addDiagnosis(props.enter));
                props.close();
            } else {
                dispatch(therapySlice.actions.setSelectedItem(props.enter))
                props.close();
                dispatch(therapyActions.toggleModal(true))
                dispatch(therapyActions.updateTherapy(null))
            }
        }
    }, [props.enter])

    return (
        <>
            <div className={styles.SearchItem} data-toggle="modal" onClick={clickItemHandler} >
                <div className={styles.IconWrapper}><PlusIcon className={styles.IconStyle}/></div>
                <span key={props.item.id} className={styles.SearchItemText}>
                    {props.diagnosis ? (props.item.code + " - " + (props.item.latinName ? props.item.latinName : props.item.name)) : props.item.name}
                </span>
            </div>
        </>
    );
};

export default SearchItem;