import React, {useContext} from 'react';
import styles from './ComponentToPrint.module.css';
import AuthContext from "../../store/context/AuthContext/auth-context";
import Logo from "../HeaderComponent/Logo/Logo";
import {ReactComponent as DrIcon} from "./../../assets/doctor_icon.svg";
import ArticlesList from "../TherapyBoard/ArticlesList/ArticlesList";
import DiagnosisList from "../TherapyBoard/DiagnosisList/DiagnosisList";

const ComponentToPrint = React.forwardRef((props, ref) => {

    const currenUser = useContext(AuthContext)
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let area1 = localStorage.getItem('textArea1') && JSON.parse(localStorage.getItem('textArea1').split('\n'));
    let area2 = localStorage.getItem('textArea2') && JSON.parse(localStorage.getItem('textArea2').split('\n'));
    let area3 = localStorage.getItem('textArea3') && JSON.parse(localStorage.getItem('textArea3').split('\n'));

    return (
        <div ref={ref} className={styles.ComponentToPrintWrapper}>
            <Logo />
            <br/><br/>
            <div className={styles.DrNameAndDate}>
                <div style={{display:"flex", alignItems:"center"}}>
                    <DrIcon/>
                    &nbsp;&nbsp;
                    <div className={styles.DrName}>Terapija {currenUser.currentUser &&
                        <span style={{fontWeight:"bold"}}>{currenUser.currentUser.firstName} {currenUser.currentUser.secondName}</span>}</div>
                </div>
                <span style={{fontWeight:"bold"}}>{day}.{month}.{year}</span>
            </div>
            <ArticlesList forPrint/>
            <DiagnosisList forPrint />
            <p>Anamneza:</p>
            <div style={{border: "1px solid lightgray", padding:"1rem", borderRadius:"6px", marginBottom:"16px", whiteSpace:"pre"}}>{area1 && area1}</div>
            <p>Polje 2:</p>
            <div style={{border: "1px solid lightgray", padding:"1rem", borderRadius:"6px", marginBottom:"16px", whiteSpace:"pre"}}>{area2 && area2}</div>
            <p>Polje 3:</p>
            <div style={{border: "1px solid lightgray", padding:"1rem", borderRadius:"6px", marginBottom:"16px", whiteSpace:"pre"}}>{area3 && area3}</div>
        </div>
    );
});

export default ComponentToPrint;