import React from 'react';
import ArticleDescription from "./ArticleDescription/ArticleDescription";
import {useSelector} from "react-redux";
import AvailabilityBadge from "../../../UI/AvailabilityBadge/AvailabilityBadge";

const ArticleData = () => {

    const articleInfo = useSelector(state => state.articleInfo.articleInfo)
    const numberOfMarketplacesForArticle = useSelector(state => state.therapy.numberOfMarketplacesForArticle);

    return (
        articleInfo &&
        <div>
            <ArticleDescription title="Broj apoteka u kojima je dostupan artikal" text={numberOfMarketplacesForArticle}/>
            <div className="my-2"><strong>Opšte informacije</strong></div>
            <section>
                <ArticleDescription title="Naziv artikla" text={articleInfo.name}/>

                <ArticleDescription title="Režim izdavanja" text={[
                    <AvailabilityBadge key={1} itemAvailabilityColor={articleInfo.itemAvailabilityColor} marginRight="8px">
                        {articleInfo.itemAvailabilityCode}
                    </AvailabilityBadge>,
                    articleInfo.itemAvailability
                ]}/>
                {articleInfo.jkl &&
                    <ArticleDescription title="JKL" text={articleInfo.jkl}/>
                }
                {/*Only medicine will have extraData*/}
                {articleInfo.extraData &&
                    <>
                        <ArticleDescription title="Aktivna substanca" text={articleInfo.extraData.activeIngredient}/>
                        <ArticleDescription title="ATC" text={articleInfo.extraData.atc}/>
                        <ArticleDescription title="Farmaceutski oblik" text={articleInfo.extraData.pharmaceuticalForm}/>
                        <ArticleDescription title="Registraciono ime" text={articleInfo.extraData.registrationName}/>
                        <ArticleDescription title="Registracioni broj" text={articleInfo.extraData.registrationId}/>
                    </>
                }

                <ArticleDescription title="Proizvođač" text={articleInfo.manufacturerName}/>
                <ArticleDescription title="Zemlja porekla" text={articleInfo.manufacturerState}/>
                {articleInfo.itemLoyalityPointsMedic > 0 &&
                    <ArticleDescription title="Loyalty bodovi" text={articleInfo.itemLoyalityPointsMedic}/>
                }
            </section>
        </div>
    );
};

export default ArticleData;