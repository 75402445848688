import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    email: null,
    articles: [],
    articlesForTherapyItemRequests: [],
    diagnosis: [],
    selectedItem: null,
    showModal: false,
    therapyForUpdate: null,
    showTherapySentModal: false,

    fetchNumberOfMarketplacesForArticleStart: false,
    numberOfMarketplacesForArticle: null,
    fetchNumberOfMarketplacesForArticleFailed: false,
}

const therapySlice = createSlice({
    name: 'therapy',
    initialState: initialState,
    reducers: {
        addArticleToTherapy(state, action) {
             if (state.articles.map( el => el.item.id).includes(action.payload.item.id)) {
                 state.articles.map((el, index) => {
                     if (el.item.id === action.payload.item.id) {
                         state.articles.splice(index, 1, action.payload)
                         //prepakivanje item-a za slanje
                         state.articlesForTherapyItemRequests.splice(index, 1,{
                             itemId: action.payload.item.id,
                             frequency: action.payload.frequency !== '/' ? action.payload.frequency : null,
                             dosing: action.payload.dosing !== '/' ? action.payload.dosing : null,
                             duration: action.payload.duration !== '/' ? action.payload.duration : null,
                             quantity: action.payload.quantity
                         });
                     }
                 })
             } else {
                 state.articles.push(action.payload);
                 //prepakivanje item-a za slanje
                 state.articlesForTherapyItemRequests.push({
                     itemId: action.payload.item.id,
                     frequency: action.payload.frequency !== '/' ? action.payload.frequency : null,
                     dosing: action.payload.dosing !== '/' ? action.payload.dosing : null,
                     duration: action.payload.duration !== '/' ? action.payload.duration : null,
                     quantity: action.payload.quantity
                 });
             }
        },
        removeArticleFromTherapy(state, action) {
            state.articles = state.articles.filter(a => a.item.id !== action.payload)
            state.articlesForTherapyItemRequests = state.articlesForTherapyItemRequests.filter(a => a.itemId !== action.payload)
        },
        removeAllArticlesAndDiagnosisFromTherapy(state) {
            state.articles = [];
            state.articlesForTherapyItemRequests = [];
            state.diagnosis = [];
            document.getElementById('form').reset();
        },
        addDiagnosis(state, action) {
            // dodaje dijagnozu u niz samo ako vec nije dodata - dok je bilo moguce dodavati vise dijagnoza
            // if (!state.diagnosis.map(el => el.id).includes(action.payload.id)) {
            //     state.diagnosis.push(action.payload)
            // }
            state.diagnosis.splice(0, 1, action.payload)
        },
        removeDiagnosis(state, action) {
            const diagnosisId = action.payload
            state.diagnosis = state.diagnosis.filter(d => d.id !== diagnosisId)
        },
        setSelectedItem(state,action) {
            state.selectedItem = action.payload;
        },
        toggleModal(state, action) {
            state.showModal = action.payload
        },
        updateTherapy(state, action) {
            state.therapyForUpdate = action.payload
        },
        toggleTherapySentModal(state, action) {
            state.showTherapySentModal = action.payload
        },
        fetchNumberOfMarketplacesForArticleStart(state) {
            state.fetchNumberOfMarketplacesForArticleStart = true;
            state.fetchNumberOfMarketplacesForArticleFailed = false;
            state.numberOfMarketplacesForArticle = null;
        },
        numberOfMarketplacesForArticle(state, action) {
            state.numberOfMarketplacesForArticle = action.payload;
            state.fetchNumberOfMarketplacesForArticleStart = false;
            state.fetchNumberOfMarketplacesForArticleFailed = false;
        },
        numberOfMarketplacesForArticleFailed(state) {
            state.fetchNumberOfMarketplacesForArticleFailed = true;
            state.fetchNumberOfMarketplacesForArticleStart = false;
            state.numberOfMarketplacesForArticle = null;
        }

    }
});

export const therapyActions = therapySlice.actions

export default therapySlice;