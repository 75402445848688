import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import DiagnosisItem from "./DiagnosisItem/DiagnosisItem";
import {therapyActions} from "../../../store/therapy-slice";

const DiagnosisList = () => {
    const dispatch = useDispatch();
    const allDiagnosis = useSelector((state) => state.therapy.diagnosis)


    const removeAction = (itemId) =>{
        dispatch(therapyActions.removeDiagnosis(itemId))
    }

    return (
        <div style={{width:"100%"}}>
            {allDiagnosis !==undefined && allDiagnosis.map( item =>
                <DiagnosisItem
                    key={item.id}
                    item={item}
                    removeItem={()=>removeAction(item.id)}
                />
            )}
        </div>
    );
};

export default DiagnosisList;