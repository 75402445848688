import axios from './../axios/axios'
import {therapyActions} from "./therapy-slice";

export const sendTherapy = (therapy)=> {
    return async (dispatch) => {
        await axios.post('/user_therapy', therapy)
            .then(response => {
                // console.log('Uspesno poslata terapija')
                dispatch(therapyActions.removeAllArticlesAndDiagnosisFromTherapy());
                dispatch(therapyActions.toggleTherapySentModal(true))
            })
            .catch(error => {
                console.log('Error pri slanju terapije ' + error);
            })
    }
}

export const fetchNumberOfMarketplacesForArticle = (articleId)=> {
    return async (dispatch) => {
        dispatch(therapyActions.fetchNumberOfMarketplacesForArticleStart())
        const URL = '/item_in_marketplace/count?filter=item.id:' + articleId;
        axios.get(URL)
            .then(response => {
                // console.log(response.data)
                dispatch(therapyActions.numberOfMarketplacesForArticle(response.data));

            })
            .catch(error => {
                dispatch(therapyActions.numberOfMarketplacesForArticleFailed());
                console.log('Error fetching marketplace count ' + error);
            })
    }
}