import React from 'react';
import ConfigData from "../../../../config.json";
import DefaultImage from "../../../../assets/PictureHolder.png";
import styles from './ArticlePicture.module.css'
import {useSelector} from "react-redux";
import {Alert} from "react-bootstrap";

const ArticlePicture = () => {

    const articleInfo = useSelector(state => state.articleInfo.articleInfo)

    return (
        articleInfo !== null ?
        <div className={styles.ArticlePictureWrapper}>
            <img src={articleInfo && articleInfo.pictures ? ConfigData.ArticleImageLink + articleInfo.pictures : DefaultImage}
                 alt="fotografija pakovanja artikla"
                 className={styles.ArticlePicture}/>
        </div>
            :
            <Alert  variant={'danger'}>
                <div>Nema podataka o traženom artiklu!</div>
            </Alert>
    );
};

export default ArticlePicture;