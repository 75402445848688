import React, {useContext, useState} from "react";
import styles from './SearchBar.module.css';
import {FuzzySortContext} from "../../../../store/context/FuzzySort/FuzzySort";
import SearchItems from "../SearchItems/SearchItems";
import {Form} from "react-bootstrap";
import AddArticleModal from "../../../Modals/AddArticleModal/AddArticleModal";
import {useSelector} from "react-redux";
import Backdrop from "../../../UI/Backdrop/Backdrop";

const SearchBar = (props) => {

    const [searchStr, setSearchStr] = useState("");
    const [searchRes, setSearchRes] = useState(null);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const selectedItem = useSelector(state=> state.therapy.selectedItem)
    const showModal = useSelector( state => state.therapy.showModal)

    const fuzzySort = useContext(FuzzySortContext);


    const handleChange = (e) => {
        setShowSearchResults(true)
        setSearchStr(e.target.value);
        if(e.target.value.length >= 3) {
            setTimeout(()=> {
                setSearchRes(e.target.value ? filterItems() : []);
            }, 600)
        } else {
            setShowSearchResults(false);
        }
        if(e.target.value === '') {
            setShowSearchResults(false);
        }
    };

    const resetSearch = () => {
        !props.diagnosis && setShowSearchResults(false);
        setSearchStr("");
        setSearchRes(null)
    }

    const filterItems = () => {

        const searchStringWords = searchStr.split(' ').filter(string => {
            if (string.trim() !== '') {
                return string.trim();
            }
        });

        let results = fuzzySort.filterByWords(props.data, searchStringWords, 0);
        results = fuzzySort.sortResultsByStartWord(results, searchStringWords[0]);

        return (results);
    }

    const backdropClickHandler = () => {
        setShowSearchResults(false);
        setSearchStr("");
    }

    return (
        <div style={{position:"relative", zIndex:"1"}}>
                    <Form.Control
                        style={{position:"relative", borderRadius:"6px"}}
                        placeholder={props.diagnosis ? "Pretražite dijagnoze" : "Pretražite lekove"}
                        type="text"
                        name="search-box"
                        value={searchStr}
                        onChange={event => handleChange(event)}
                        autoComplete = "off"
                    />
            {showSearchResults &&
                <>
                    <SearchItems className={styles.SearchItems}
                                 style={!showSearchResults || searchRes === null || searchRes === " "
                                     ? {display: "none"}
                                     : {zIndex: 1, backgroundColor: "white"}} results={searchRes}
                                 diagnosis={props.diagnosis}
                                 close={resetSearch}
                    />
                    <Backdrop zIndex="-1" show={showSearchResults} backdropClickHandler={backdropClickHandler}/>
                </>
            }

            {!props.diagnosis && showModal &&
                <AddArticleModal
                    showModal={showModal}
                    item={selectedItem}
                />
            }
        </div>
    );
}

export default SearchBar;