import {configureStore} from '@reduxjs/toolkit'

import therapySlice from "./therapy-slice";
import articleInfoSlice from "./articleInfo-slice";
import profileSlice from "./profile-slice";

const store = configureStore({
    reducer: {
        therapy: therapySlice.reducer,
        articleInfo: articleInfoSlice.reducer,
        profile: profileSlice.reducer
    }
})


export default store;



