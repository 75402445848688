import React from 'react';
import styles from './DrugInfo.module.css'
import {Accordion} from "react-bootstrap";

const DrugInfo = (props) => {

    return (
        props.article &&
        <div className={styles.DrugInfoWrapper}>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Terapijske indikacije</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.indications}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Doziranje i način primene</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.dosing}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Kontraindikacije</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.contraindications}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Posebna upozorenja</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.warnings}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Interakcije</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.interactions}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Trudnoća i dojenje</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.pragnancy}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Upravljanje vozilom</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.driving}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>Neželjena dejstva</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.undesirable}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>Predoziranje</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.overdose}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>Farmakodinamika</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.pharmacodynamic}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                    <Accordion.Header>Farmakokinetika</Accordion.Header>
                    <Accordion.Body>
                        <div dangerouslySetInnerHTML={{__html: props.article.pharmacokinetic}}
                             style={{padding: "1rem"}}></div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default DrugInfo;