import React from 'react';
import styles from './ArticleDescription.module.css';

export const ArticleDescription = (props) => {
    return(
        <div className={styles.ArticleDescription}>
            <span className={styles.Title}>{props.title}</span>
            <span className={styles.Text}>{props.text}</span>
        </div>
    );
}

export default ArticleDescription;